import {Dialog, DialogBody} from '@/Components/Catalyst/dialog'
import React, {FormEventHandler, useState} from 'react'
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {useForm} from "@inertiajs/react";
import {SparklesIcon} from "@heroicons/react/24/solid";

interface NewMealPlanDialogProps {
  recipeQuickAdd?: App.Data.RecipeData
}

export default function NavSearchDialog(props: NewMealPlanDialogProps) {
  let [isOpen, setIsOpen] = useState(false)
  const {
    data,
    setData,
    post,
    processing,
  } = useForm({
    search: '',
  });


  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    await post(route('search', {
      term: data.search,
    }))
    setIsOpen(false)
    setData('search', '')
  };

  return (
    <>
      <MagnifyingGlassIcon
        className='h-auto w-5 cursor-pointer pr-1 text-black'
        onClick={() => {
          setIsOpen(true)
          document.getElementById('#input-search')?.focus()
        }}
      />

      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogBody>
          <form onSubmit={handleSubmit} className='flex gap-2'>
            <input
              id='input-search'
              type='text'
              value={data.search}
              placeholder="Search"
              className="p-4 w-full m-0 outline-none bg-transparent text-slate-800 rounded border-emerald-600 focus:ring-0 focus:border-emerald-600"
              onChange={(e) => setData('search', e.target.value.toLowerCase())}
            />
            <button type="submit" color='white' className='border-0' disabled={processing}>
              {processing ? <SparklesIcon className='size-7 text-emerald-800'/> :
                <MagnifyingGlassIcon className='size-7 text-black'/>}
            </button>
          </form>
        </DialogBody>
      </Dialog>
    </>
  )

}
