import {FunctionComponent} from "react";
import Checkbox from "@/Components/Ui/Form/Checkbox";
import Api from "@/Api";
import {ShoppingListService} from "@/containers/ShoppingListContainer";

const Item: FunctionComponent<{ item: App.Data.ShoppingListItemData }> = (props) => {
  const shoppingListService = ShoppingListService.useContainer()

  const unitName = props.item.unit.name === 'count'
    ? ''
    : props.item.unit.name;
  
  return (
    <li className="flex gap-x-4 py-3">
      <Checkbox
        className='h-7 w-7 flex-none rounded-full bg-gray-50 hover:cursor-pointer'
        onInput={async () => {
          await Api.shopping.removeItem(props.item)
          shoppingListService.removeItem(props.item)
        }}/>

      <div className="min-w-0">
        <p
          className="inline-block min-w-80 text-sm font-semibold leading-1 text-gray-900">{props.item.ingredient.name}</p>
        <span className="truncate text-xs leading-1 text-gray-500">
          {props.item.quantity} {unitName}
          {props.item.ingredient.location?.length && ` (${props.item.ingredient.location})`}
        </span>
      </div>

      <div className='divide-zinc-400'/>
    </li>
  )
}

export default Item
