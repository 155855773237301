import {PropsWithChildren} from "react";
import NavigationSidebar from "@/Layouts/partials/NavigationSidebar";
import {StackedLayoutUnconstrained} from "@/Components/Catalyst/stacked-layout-unconstrained";
import Navigation, {NavigationItem} from "@/Layouts/partials/Navigation";
import Transition from "@/Components/Transition";
import UserData = App.Data.UserData;

const navItems: NavigationItem[] = [
  {label: 'Home', href: route('welcome')},
  {label: 'Recipes', href: '/recipes'},
  {label: 'My Mission', href: route('mission')},
  // {label: 'Blog', href: route('blog')},
  {label: 'Pantry', href: route('pantry'),},
]

export default function MemberLayout({user, children}: PropsWithChildren<{
  user?: UserData,
}>) {
  return (
    <StackedLayoutUnconstrained
      navbar={
        <Navigation navItems={navItems} user={user}/>
      }
      sidebar={
        <NavigationSidebar items={navItems} user={user}/>
      }
    >
      <Transition>
        {children}
      </Transition>
    </StackedLayoutUnconstrained>
  )
}
