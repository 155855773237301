import {Dropdown, DropdownButton} from "@/Components/Catalyst/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer
} from "@/Components/Catalyst/navbar";
import {ChevronDownIcon, UserIcon} from "@heroicons/react/16/solid";
import MealPlanDropdownMenu from "@/Layouts/partials/MealPlanDropdownMenu";
import DesktopNavBarSection from "@/Layouts/partials/DesktopNavBarSection";
import UserDropdownMenu from "@/Layouts/partials/UserDropdownMenu";
import NavSearch from "@/Components/NavSearch";
import {Link} from "@/Components/Catalyst/link";
import NavSearchDialog from "@/Components/Dialogs/NavSearchDialog";
import ShoppingList from "@/Components/Sidepanels/ShoppingList";
import UserData = App.Data.UserData;

export interface NavigationItem {
  label: string,
  href: string,

}

interface NavigationProps {
  navItems: NavigationItem[]
  user?: UserData,
}

export default function Navigation({navItems, user}: NavigationProps) {
  const transition = 'hover:text-emerald-600 transition-all ease-in-out duration-200';
  return (
    <Navbar className="mx-auto max-w-7xl ">
      <NavbarSection className=''>
        <Link href={route('welcome')}>
          <span
            className={`m-0 p-0 text-lg text-emerald-800 font-bold ${transition}`}>PANTRY</span>
          <span
            className={`m-0 p-0 text-md text-black ${transition}`}>savvy</span>
        </Link>
      </NavbarSection>
      {user && user.isSubscribedOrOnTrial &&
        <>
          <Dropdown>
            <DropdownButton as={NavbarItem} className="max-lg:hidden cursor-pointer">
              <NavbarLabel className="cursor-pointer">Meal Plans</NavbarLabel>
              <ChevronDownIcon className="cursor-pointer"/>
            </DropdownButton>
            <MealPlanDropdownMenu mealPlans={user.mealPlans}/>
          </Dropdown>

          <NavbarDivider className="max-lg:hidden"/></>
      }

      <DesktopNavBarSection items={navItems}/>

      <NavbarSpacer/>

      <NavbarSection>
        <div className='hidden lg:block'><NavSearch/></div>
        <div className='lg:hidden'><NavSearchDialog/></div>
        {user && user.isSubscribedOrOnTrial && <div className=''><ShoppingList/></div>}
        <Dropdown>

          {user &&
            <DropdownButton as={NavbarItem}>
              <UserIcon className="h-auto w-5 hover:cursor-pointer"/>
            </DropdownButton>}

          {user &&
            <UserDropdownMenu/>
          }


          {!user && (
            <>
              <NavbarItem href={route('login')}>Login</NavbarItem>
              <NavbarItem href={route('register')}>Register</NavbarItem>
            </>
          )}

        </Dropdown>
      </NavbarSection>
    </Navbar>
  )
}
