import {NavbarItem, NavbarSection} from "@/Components/Catalyst/navbar";
import {NavigationItem} from "@/Layouts/partials/Navigation";

interface DesktopNavBarSectionProps {
  items: NavigationItem[]

}

export default function DesktopNavBarSection({items}: DesktopNavBarSectionProps) {
  return (
    <NavbarSection className="max-lg:hidden">
      {
        items.map(({label, href}) => (
          <NavbarItem key={label} href={href}>
            {label}
          </NavbarItem>
        ))
      }
    </NavbarSection>
  );
}
