import {DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu} from "@/Components/Catalyst/dropdown";
import {ArrowRightStartOnRectangleIcon, LightBulbIcon, UserIcon} from "@heroicons/react/24/outline";

export default function UserDropdownMenu() {
  return (
    <DropdownMenu className="min-w-64" anchor="bottom end">
      <DropdownItem href={route('profile.edit')} className='hover:cursor-pointer'>
        <UserIcon/>
        <DropdownLabel>My profile</DropdownLabel>
      </DropdownItem>
      <DropdownDivider/>
      <DropdownDivider/>
      <DropdownItem href={route('feedback')} className='hover:cursor-pointer'>
        <LightBulbIcon/>
        <DropdownLabel>Share feedback</DropdownLabel>
      </DropdownItem>
      <DropdownDivider/>
      <DropdownItem href={route('out')} className='hover:cursor-pointer'>
        <ArrowRightStartOnRectangleIcon/>
        <DropdownLabel>Sign out</DropdownLabel>
      </DropdownItem>
    </DropdownMenu>
  )
}
