import {DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu} from "@/Components/Catalyst/dropdown";
import {PlusIcon} from "@heroicons/react/16/solid";
import {Avatar} from "@/Components/Catalyst/avatar";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "@/Components/Catalyst/dialog";
import {Button} from "@/Components/Catalyst/button";
import {useState} from "react";
import NewMealPlanForm from "@/Components/Forms/NewMealPlanForm";
import {useCustomEventListener} from "react-custom-events";
import {usePage} from "@inertiajs/react";
import {EyeIcon} from "@heroicons/react/24/solid";
import MealPlanData = App.Data.MealPlanData;

interface MealPlanDropdownMenuProps {
  mealPlans: MealPlanData[]

}

export default function MealPlanDropdownMenu({mealPlans}: MealPlanDropdownMenuProps) {
  let [isOpen, setIsOpen] = useState<boolean>(false)
  const pageProps: any = usePage().props

  const [
    stateMealPlans,
    setMealPlans
  ] = useState<MealPlanData[]>(mealPlans)

  const handleMealPlanCreated = (mealPlanData: MealPlanData) => {
    setMealPlans([...stateMealPlans, mealPlanData])
  }

  useCustomEventListener('meal-plan::created', (mealPlanData: MealPlanData) => {
    handleMealPlanCreated(mealPlanData)
  }, [stateMealPlans, setMealPlans])

  return (
    <>
      <DropdownMenu className="min-w-80 lg:min-w-64 cursor-pointer" anchor="bottom start">

        {!!stateMealPlans.length &&
          <>
            <DropdownItem href={route('meal-plans')} className="cursor-pointer">
              <EyeIcon/>
              <DropdownLabel>All Meal Plans</DropdownLabel>

            </DropdownItem>
            <DropdownDivider/>
          </>
        }

        {
          stateMealPlans && stateMealPlans.map((mealPlan) => {
            return (
              <DropdownItem
                key={mealPlan.uuid}
                href={route('meal-plan', {mealPlan: mealPlan.uuid})}
                className="cursor-pointer"
              >
                <Avatar slot="icon" initials="MP" className="bg-emerald-600 text-white"/>
                <DropdownLabel>{mealPlan.name}</DropdownLabel>
              </DropdownItem>
            )
          })
        }
        {!!stateMealPlans.length && <DropdownDivider/>}
        <DropdownItem className="cursor-pointer" onClick={() => setIsOpen(true)}>
          <PlusIcon/>
          <DropdownLabel>New Meal Plan</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>

      <Dialog size="xl" open={isOpen} onClose={setIsOpen}>
        <DialogTitle>New Meal Plan</DialogTitle>
        <DialogDescription>
          Every good meal plan starts with a name.
        </DialogDescription>
        <DialogBody>
          <NewMealPlanForm/>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
