import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection
} from "@/Components/Catalyst/sidebar";
import {Dropdown, DropdownButton} from "@/Components/Catalyst/dropdown";
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import MealPlanDropdownMenu from "@/Layouts/partials/MealPlanDropdownMenu";
import {NavigationItem} from "@/Layouts/partials/Navigation";
import UserData = App.Data.UserData;

interface DesktopNavBarSectionProps {
  items: NavigationItem[]
  user?: UserData,
}

export default function NavigationSidebar({items, user}: DesktopNavBarSectionProps) {
  return (
    <Sidebar>
      {
        user && user.isSubscribedOrOnTrial &&
        (
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <SidebarLabel>Meal Plans</SidebarLabel>
                <ChevronDownIcon/>
              </DropdownButton>
              <MealPlanDropdownMenu mealPlans={user.mealPlans}/>
            </Dropdown>
          </SidebarHeader>
        )
      }
      <SidebarBody>
        <SidebarSection>
          {items.map(({label, href}) => (
            <SidebarItem key={label} href={href}>
              {label}
            </SidebarItem>
          ))}
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  )
}
