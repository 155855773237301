'use client'

import {useEffect, useState} from 'react'
import {Dialog, DialogPanel, DialogTitle} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import {ShoppingCartIcon} from '@heroicons/react/20/solid'
import {NavbarItem} from "@/Components/Catalyst/navbar";
import {Text} from "@/Components/Catalyst/text";
import Item from "@/Components/ShoppingList/Item";
import {Heading} from "@/Components/Catalyst/heading";
import {ShoppingListService} from "@/containers/ShoppingListContainer";
import Api from "@/Api";
import {useCustomEventListener} from "react-custom-events";
import ShoppingListData = App.Data.ShoppingListData;

export default function ShoppingList() {
  const shoppingListService = ShoppingListService.useContainer()
  const [open, setOpen] = useState(false)
  const [shoppingList, setShoppingList] = useState<ShoppingListData>(shoppingListService.list)

  useEffect(() => {
    setShoppingList(shoppingListService.list)
  }, [shoppingList, setShoppingList, shoppingListService])

  useCustomEventListener('recipe::addedToShoppingList', (event) => setOpen(true))
  useCustomEventListener('meal-plan::addedToShoppingList', (event) => setOpen(true))
  useCustomEventListener('shopping-list::cleared', (event) => setOpen(false))

  return (
    <>
      <NavbarItem onClick={() => setOpen(true)}>
        <strong
          className="relative inline-flex items-center px-2.5 py-1.5 text-xs font-medium">
            <span
              className="absolute -top-1 -right-1 h-5 w-5 rounded-full bg-emerald-600 flex justify-center items-center items text-white font-bold">
              <span>{shoppingList?.items?.length ?? 0}</span>
            </span>
          <span className="text-green-700">
              <ShoppingCartIcon className="h-auto w-5 text-black hover:cursor-pointer"/>
            </span>
        </strong>
      </NavbarItem>
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <div className="fixed inset-0"/>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-emerald-700 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <DialogTitle className="text-base font-semibold text-white">Shopping List</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="relative rounded-md bg-emerald-700 text-emerald-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          >
                            <span className="absolute -inset-2.5"/>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="size-6"/>
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <Text className="text-sm text-white">
                          Please double check your home pantry for any items you already have, or that may be expired
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        {shoppingList &&
                          <div className="space-y-6 pb-5 pt-6">
                            <div>
                              <h3 className="text-sm/6 font-medium text-gray-900">Items
                                ({shoppingList?.items?.length ?? 0})</h3>
                              <div className="mt-2">
                                <div className="flex space-x-2">
                                  <div className="mx-auto lg:mx-0">
                                    {!!shoppingList?.items?.length &&
                                      <ul role="list" className="divide-y divide-gray-100">
                                        {shoppingList.items.sort(
                                          (a, b) => (a.ingredient.location ?? '').localeCompare(b.ingredient.location ?? '')
                                        ).map((item) => (
                                          <Item item={item} key={item.uuid}/>
                                        ))}
                                      </ul>}

                                    {!shoppingList?.items?.length &&
                                      <Heading>
                                        It looks like there's nothing here.
                                        Go have a cup of relaxation.
                                      </Heading>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>}
                        {/*<div className="pb-6 pt-4">
                          <div className="flex text-sm">
                            <a
                              href="#"
                              className="group inline-flex items-center font-medium text-emerald-600 hover:text-emerald-900"
                            >
                              <LinkIcon
                                aria-hidden="true"
                                className="size-5 text-emerald-500 group-hover:text-emerald-900"
                              />
                              <span className="ml-2">Share link</span>
                            </a>
                          </div>
                          <div className="mt-4 flex text-sm">
                            <a href="#" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                              <QuestionMarkCircleIcon
                                aria-hidden="true"
                                className="size-5 text-gray-400 group-hover:text-gray-500"
                              />
                              <span className="ml-2">Learn more about sharing shopping lists</span>
                            </a>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                  <div className="flex shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      onClick={async () => {
                        const result = await Api.shopping.clearList(shoppingListService.uuid)
                        if (result.status === 200) {
                          shoppingListService.clearList()
                          setOpen(false)
                        }
                      }}
                      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Clear
                    </button>
                    <button
                      onClick={() => setOpen(false)}
                      className="ml-4 inline-flex justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
