import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {FormEventHandler, useState} from "react";
import {useForm} from "@inertiajs/react";
import {motion} from "framer-motion";
import {SparklesIcon} from "@heroicons/react/24/solid";

export default function NavSearch() {

  const [
    showInput,
    setShowInput
  ] = useState<boolean>(false)

  const {
    data,
    setData,
    post,
    processing,
  } = useForm({
    search: '',
  });


  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    post(route('search', {
      term: data.search,
    }))
  };

  return (
    <div className={`
      flex flex-row gap-x-3
      border rounded-md 
      transition-all duration-300 ease-in-out
      ${showInput ? 'border-gray-300' : 'border-white'}
    `}>
      {
        showInput &&
        <form onSubmit={handleSubmit}>
          <motion.input
            layout
            id='input-search'
            type='text'
            value={data.search}
            placeholder="Search"
            className="transition-all ease-linear duration-200 p-1 w-full lg:w-96 p-0 m-0 outline-none bg-transparent text-slate-800 focus:ring-0 border-0"
            onChange={(e) => setData('search', e.target.value.toLowerCase())}
            onBlur={() => setShowInput(false)}
          />
        </form>
      }

      {!processing && <MagnifyingGlassIcon
        className='h-auto w-5 cursor-pointer pr-1 text-black'
        onClick={() => {
          setShowInput(!showInput)
          document.getElementById('#input-search')?.focus()
        }}
      />}

      {processing && <SparklesIcon
        className='h-auto w-5 cursor-pointer pr-1 text-black'
      />
      }

    </div>
  )
}
